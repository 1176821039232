import {
  Html,
  PresentationControls,
  Preload,
  useAnimations,
  useGLTF,
  Loader,
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Suspense, useEffect } from 'react'

export const MyLoader = () => {
  return (
    <Html>
      <Loader />
    </Html>
  )
}

const Avatar = () => {
  const avatar = useGLTF('images/adz1.glb')
  const { actions, names } = useAnimations(avatar.animations, avatar.scene)
  useEffect(() => {
    console.log(actions)
    actions[names[0]]?.reset().fadeIn(0.5).play()

    return () => {
      actions[names[0]]?.fadeOut(0.5)
    }
  }, [actions, names])

  return (
    <group>
      <primitive
        object={avatar.scene}
        scale={2}
        position-z={0}
        position-y={-2}
        rotation-y={0}
        position-x={1}
      />
    </group>
  )
}

export const AvatarCanvas = () => {
  return (
    <Canvas>
      <ambientLight intensity={0.55} />
      {/* <pointLight position={[0, 0, 0]} /> */}
      <pointLight position={[0, 20, 10]} intensity={1.5} />
      <PresentationControls
        global
        rotation={[0, 0, 0]}
        polar={[0, 0, 0]}
        enabled={false}
      >
        <Suspense fallback={<MyLoader />}>
          <Avatar />
        </Suspense>
        <Preload all />
      </PresentationControls>
    </Canvas>
  )
}
