import { data } from '../../data/p'
import { SlClose } from 'react-icons/sl'
import './index.scss'
import ImageSlider from '../ImageSlider'
const Modal = ({
  modalOpen,
  setModalOpen,
  project,
  projectType,
  modalView,
}) => {
  const p = data[projectType][project]
  return (
    <div className={`modal-container ${modalOpen ? null : 'closed'}`}>
      <div className='w-100 h-100'>
        {modalView === 'about' && (
          <>
            <SlClose
              onClick={() => setModalOpen(false)}
              className='clickable close-btn'
            />
            <h1>about me shit</h1>
          </>
        )}
        {modalView === 'project' && (
          <div className='w-100 h-100 project-container'>
            <SlClose
              onClick={() => setModalOpen(false)}
              className='clickable close-btn hello'
            />
            <div className='bg-black photo-container position-relative'>
              <ImageSlider images={p.imageBank} />
            </div>
            <div className='px-4 text-container'>
              <div className='w-100 p-4'>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p className='text-lg-p1 mono'>
                      {p.type.toUpperCase()} PROJECT
                    </p>
                    <h1 className='text-lg-modal'>{p.title.toUpperCase()}</h1>
                    <p
                      className='text-lg-p1 mono'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {p.description}
                    </p>
                  </div>
                </div>
                <div className='d-flex mt-3'>
                  <a
                    href={p.live}
                    className='btn btn-outline-black rounded-pill mono text-lg-p2 project-pill mr-2'
                  >
                    LIVE
                  </a>
                  {p.type &&
                    p.type !== 'professional' &&
                    p.type !== 'Creative Direction' &&
                    p.type !== 'Design' &&
                    p.github && (
                      <a
                        href={p.github}
                        className='btn btn-outline-black rounded-pill mono text-lg-p2 project-pill '
                      >
                        GITHUB
                      </a>
                    )}
                </div>
                {p.type && p.type !== 'personal' && (
                  <div className='mt-5 dtails'>
                    <h2 className='text-lg-h2'>DETAILS</h2>
                    <hr />
                    <p
                      className='text-lg-p1 mono mt-2'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {p.details}
                    </p>
                  </div>
                )}
                {/*p.demoVideos && <button>View Demos</button>*/}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
