import './index.scss'

const Card = ({ image, title, type, handleProjectClick, index }) => {
  return (
    <div className='col-lg-4 col-md-6 col-12 mb-3'>
      <div
        className='p-card d-flex flex-column align-items-center justify-content-center clickable'
        onClick={() => handleProjectClick(index)}
      >
        <img src={image} className='w-100 no-too-big' alt='card' />
        <div className='w-100 px-3 d-flex align-items-center justify-content-between'>
          <p className='text-lg-h4 title'>{title.toUpperCase()}</p>
          <p className='text-lg-h4'>{type.toUpperCase()}</p>
        </div>
      </div>
    </div>
  )
}

export default Card
