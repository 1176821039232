import React, { useState } from 'react'
import './index.scss'

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='accordion'>
      <div className='accordion-header' onClick={toggleAccordion}>
        <h5 className='mb-0'>
          <p className='text-lg-h3'>{title}</p>
        </h5>
      </div>
      {isOpen && <div className='accordion-body'>{content}</div>}
    </div>
  )
}

export default Accordion
