import React, { useState } from 'react'
import { BiChevronLeftCircle, BiChevronRightCircle } from 'react-icons/bi'
import './index.scss'

const ImageSlider = ({ images }) => {
  const [current, setCurrent] = useState(0)

  const nextSlide = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1)
  }

  if (!Array.isArray(images) || images.length <= 0) {
    return null
  }

  return (
    <section className='postion-relative w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
      {images.length > 1 && (
        <>
          <div
            className='text-white bg-none border-0 pointer position-absolute arrow-left'
            onClick={prevSlide}
          >
            <BiChevronLeftCircle size='2rem' />
          </div>
          <div
            className='text-white pointer position-absolute arrow-right'
            onClick={nextSlide}
          >
            <BiChevronRightCircle size='2rem' />
          </div>
        </>
      )}
      {images.map((image, index) => {
        return (
          <div
            key={index}
            className='w-100 d-flex align-items-center justify-content-center'
          >
            {index === current && (
              <img
                src={image}
                alt='project'
                className='w-100 px-4 modal-image'
              />
            )}
          </div>
        )
      })}
    </section>
  )
}

export default ImageSlider
